@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nexa';
  src: url('./assets/fonts/Nexa-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('./assets/fonts/Nexa Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('./assets/fonts/Nexa Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('./assets/fonts/Nexa Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('./assets/fonts/Nexa-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@layer base {
  h1 {
    @apply font-['Nexa'] font-[800] text-4xl;
  }
  h2 {
    @apply font-['Nexa'] font-bold text-3xl;
  }
  h3 {
    @apply font-['Nexa'] font-bold text-2xl;
  }
  h4 {
    @apply font-['Nexa'] font-[400] text-xl;
  }
  h5 {
    @apply font-['Nexa'] font-[300] text-lg;
  }
  h6 {
    @apply font-['Nexa'] font-[200] text-base;
  }
  body {
    @apply font-['Nexa'] font-[400];
  }
}
